var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-checkbox-group',{ref:"checkboxGroup",attrs:{"checked-color":"#FF4848"},model:{value:(_vm.checkResult),callback:function ($$v) {_vm.checkResult=$$v},expression:"checkResult"}},_vm._l((_vm.goods),function(item,index){return _c('div',{key:index,staticClass:"shop_product"},[(_vm.isShopTitle && index == 0)?_c('h2',{staticClass:"shop_title"},[_c('img',{attrs:{"src":"http://image.shop.19jiang.cn/home/mofang/alioss_6107944e3a051.jpg"}}),_vm._v(" 十九匠官方店 ")]):_vm._e(),_c('div',{staticClass:"product_ul"},[_c('div',{staticClass:"product_item"},[(_vm.state == 'step2')?_c('van-checkbox',{staticClass:"checkbox",attrs:{"name":("" + (item.presale_id))},on:{"click":_vm.RadioChange}}):_vm._e(),_c('li',{staticClass:"list"},[_c('div',{staticClass:"product_info",on:{"click":function($event){return _vm.$router.push({
                  name: 'HomeGoodsdetail',
                  query: { goods_id: item.detail.goods_id },
                })}}},[_c('img',{staticClass:"productImage",attrs:{"src":item.detail.goodsimage_full_url}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title t1"},[_vm._v(" "+_vm._s(item.detail.goods_name)+" ")]),_c('div',{staticClass:"price t1"},[_vm._v("￥ "+_vm._s(item.detail.goods_price))]),_c('div',{staticClass:"tips"},[_c('div',{staticClass:"t-li"},[_vm._v(" "+_vm._s(_vm.state == "step1" ? "预付定金" : "尾款待付")+" ")])])])]),_c('div',{staticClass:"product_data"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"step1"},[_c('div',{staticClass:"solid"}),_c('span',{staticClass:"s_title"},[_vm._v("商品定金")]),_c('span',{staticClass:"s_price"},[_vm._v("￥"+_vm._s(item.presale_price))]),(_vm.state == 'step1')?_c('span',{staticClass:"s_des"},[_vm._v("待付款")]):_vm._e(),(_vm.state == 'step2')?_c('span',{staticClass:"s_des",staticStyle:{"color":"#222"}},[_vm._v("已完成")]):_vm._e()]),_c('div',{staticClass:"step_payPriceText s1_payPriceText"},[_vm._v(" 实付款 "),_c('span',{staticClass:"sp_price"},[_vm._v("¥"+_vm._s(item.presale_price))])]),_c('div',{staticClass:"step2"},[_c('div',{staticClass:"solid",style:(_vm.state == 'step1' ? 'background:#999' : '')}),_c('span',{staticClass:"s_title",style:(_vm.state == 'step1' ? 'color:#999' : '')},[_vm._v("尾款总额")]),_c('span',{staticClass:"s_price",style:(_vm.state == 'step1' ? 'color:#999' : '')},[_vm._v("¥"+_vm._s(item.balance_price))]),_c('span',{staticClass:"s_des",style:(item.is_allow == 0
                      ? 'color:#999'
                      : item.is_allow == 1
                      ? ''
                      : 'color:#222')},[_vm._v(" "+_vm._s(item.is_allow == 0 ? "未开始" : item.is_allow == 1 ? "待付款" : "已结束")+" ")])]),_c('div',{staticClass:"step_payPriceText",staticStyle:{"text-align":"left"},style:(_vm.state == 'step1' ? 'color:#999' : '')},[_vm._v(" "+_vm._s(item.balance_start_date)+" 至 "+_vm._s(item.balance_end_date)+" ")]),(_vm.state == 'step2')?_c('div',{staticClass:"product_price_total"},[_c('span',[_vm._v("实付款")]),_c('span',[_vm._v("¥"+_vm._s(parseFloat(item.balance_price)))])]):_vm._e()]),(_vm.state == 'step2')?_c('div',{staticClass:"btn-ul"},[_c('div',{staticClass:"li",on:{"click":_vm.reductPrice}},[_vm._v(" 退款 ")])]):_vm._e(),(_vm.state == 'step1')?_c('div',{staticClass:"total"},[_c('span',[_vm._v("小计")]),_c('b',[_vm._v("￥"+_vm._s(parseFloat(item.presale_price)))])]):_vm._e()])],1)])])}),0),(_vm.state == 'step2')?_c('div',{staticClass:"submit_shadow flex-container",attrs:{"id":"submit"}},[[_c('van-checkbox',{staticClass:"check",attrs:{"checked-color":"#fb5839"},on:{"click":_vm.setAllCheck},model:{value:(_vm.allChecked),callback:function ($$v) {_vm.allChecked=$$v},expression:"allChecked"}},[_vm._v("全选")])],_c('div',{staticClass:"info flex1"},[_c('p',{staticClass:"data"},[_c('i',[_vm._v("合计:")]),_c('span',[_c('b',[_vm._v("￥")]),_vm._v(_vm._s(_vm.checkPrice))])]),_c('p',{staticClass:"des"},[_vm._v("活动优惠¥0")])]),_c('div',{staticClass:"submit_form",class:_vm.checkResult.length != 0 ? 'submit_form-act' : '',on:{"click":function($event){_vm.checkResult.length != 0 ? _vm.selectPay() : ''}}},[_vm._v(" 去结算 ")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
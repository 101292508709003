<template>
  <div>
    <!-- <van-contact-card type="add" @click="onAdd" /> -->

    <div class="buy_Address">
      <div
        class="b-a-tip flex-container"
        v-if="thatAddress && !thatAddress.address_id"
      >
        <img
          src="http://image.shop.19jiang.cn/home/mofang/alioss_60bd86afd76cb.jpg"
        />
        <span>1.填写地址 2.确认信息 3.完成支付</span>
      </div>

      <div
        class="address_add flex-container"
        v-if="thatAddress && !thatAddress.address_id"
        @click="addressShow = true"
      >
        <img class="chooesed" src="@image/order/order_pos2.png" />
        <p class="flex1">请填写收货地址</p>
        <img class="more" src="@image/goods/review_more.png" />
      </div>
      <div class="address_li flex-container" v-else>
        <img class="chooesed" src="@image/order/order_pos2.png" />
        <div class="data flex1">
          <div class="info flex-container">
            <p>{{ thatAddress && thatAddress.address_realname }}</p>
            <p>{{ thatAddress && thatAddress.address_mob_phone }}</p>
          </div>
          <div class="add">
            <p>{{ thatAddress && thatAddress.area_info }}</p>
          </div>
        </div>
        <img
          src="@image/goods/review_more.png"
          class="edit"
          @click="addressShow = true"
        />
      </div>
    </div>
    <van-popup
      v-model="addressShow"
      :style="{ height: '90%' }"
      position="bottom"
      round
      get-container="body"
      :close-on-click-overlay="false"
    >
      <oAddress
        @close="closePopup"
        :info.sync="thatAddress"
        :buyorder="true"
      ></oAddress>
    </van-popup>
  </div>
</template>

<script>
import { ContactCard, Popup } from "vant";
import oAddress from "@view/me/view/address/index.vue";

export default {
  components: {
    oAddress,
    vanPopup: Popup,
    vanContactCard: ContactCard,
  },
  props: {
    addressArr: {},
  },
  data() {
    return {
      addressShow: false,
      thatAddress: {},
    };
  },
  watch: {
    addressArr() {
      if (
        this.thatAddress &&
        this.addressArr &&
        this.thatAddress.address_id == this.addressArr.address_id
      ) {
        return false;
      }
      this.thatAddress = this.addressArr;
    },
    thatAddress() {
      this.$emit("update:addressArr", this.thatAddress);
    },
  },
  created() {
    this.thatAddress = this.addressArr;
  },
  methods: {
    saveAddress() {
      this.addressShow = false;
    },
    closePopup(val) {
      this.addressShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.buy_Address {
  // background: #fff;
}

.address_add {
  position: relative;
  // padding: rem(50) rem(20);
  background: #fff;
  align-items: center;
  border-radius: rem(20);
  margin: 0 auto rem(20);
  width: rem(710);
  height: rem(120);
  .chooesed {
    width: rem(70);
    height: rem(70);
    display: block;
    margin-left: rem(30);
  }
  .more {
    width: rem(30);
    margin-right: rem(20);
  }
  p {
    font-size: rem(34);
    margin-left: rem(24);
  }

  // &::before {
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   height: 2px;
  //   background: repeating-linear-gradient(
  //     -45deg,
  //     #ff6c6c 0,
  //     #ff6c6c 20%,
  //     transparent 0,
  //     transparent 25%,
  //     #1989fa 0,
  //     #1989fa 45%,
  //     transparent 0,
  //     transparent 50%
  //   );
  //   background-size: 80px;
  //   content: "";
  // }
}
.address_li {
  align-items: center;
  width: rem(710);
  margin: rem(20) auto;
  border-radius: rem(20);
  box-sizing: border-box;
  padding: rem(40) rem(24);
  background: #fff;
  border-bottom: rem(1) solid #ebebeb;

  .chooesed {
    width: rem(66);
    height: rem(66);
    margin-right: rem(26);
  }
  .data {
    .info {
      align-items: center;
      p {
        font-size: rem(32);
        color: #969696;
        &:nth-of-type(1) {
          color: #3b3b3b;
          margin-right: rem(16);
        }
      }
      span {
        display: block;
        font-size: rem(22);
        color: #ff4848;
        width: rem(60);
        height: rem(32);
        background: #ffffff;
        border: rem(1) solid #ff4848;
        border-radius: rem(4);
        text-align: center;
        margin-left: rem(16);
      }
    }
    .add {
      font-size: rem(22);
      color: #808080;
    }
  }
  .edit {
    width: rem(30);
    height: rem(30);
    margin-top: rem(20);
    margin-left: rem(40);
  }
}

.b-a-tip {
  width: rem(710);
  height: rem(112);
  background: #fceec7;
  color: #584620;
  font-size: rem(30);
  margin: rem(20) auto;
  border-radius: rem(20);
  align-items: center;
  img {
    border-radius: 50%;
    margin-left: rem(30);
    display: block;
    width: rem(66);
    height: rem(66);
  }
  span {
    margin-left: rem(30);
  }
}
</style>

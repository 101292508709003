<template>
  <div>
    <div id="submit" class="submit_shadow flex-container">
      <template>
        <van-checkbox
          class="check"
          v-model="allChecked"
          checked-color="#fb5839"
          @click="setAllCheck"
          v-if="state == 'step2'"
          >全选</van-checkbox
        >
        <div class="product_number" v-else>共{{ form.total_num || 1 }}件</div>
      </template>

      <div class="info flex1">
        <p class="data">
          <i>合计:</i
          ><span>
            <b>￥</b
            >{{
              form.total_price - ((voucher && voucher.voucher_price) || 0) || 0
            }}</span
          >
        </p>
        <p class="des">优惠¥{{ (voucher && voucher.voucher_price) || 0 }}</p>
      </div>
      <div
        class="submit_form submit_form-act"
        @click="selectPay"
        v-if="state == 'step1' || state == 'buy'"
      >
        立即支付
      </div>
      <div class="submit_form" @click="selectPay" v-if="state == 'step2'">
        去结算
      </div>
    </div>
    <vanPopup
      closeable
      round
      v-model="showPassword"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <h1 class="balance_pay_title">请输入零钱支付密码</h1>
      <!-- 密码输入框 -->
      <van-password-input
        :value="keyValue"
        :focused="showKeyboard"
        info="密码为 6 位数字"
        @focus="showKeyboard = true"
      />
      <!-- 数字键盘 -->
      <van-number-keyboard
        close-button-text="完成"
        :value="keyValue"
        :show="showKeyboard"
        @input="keyInput"
        @close="pay"
        @delete="delInput"
        theme="custom"
        :extra-key="['忘记']"
      />
    </vanPopup>
  </div>
</template>

<script>
import {
  PasswordInput,
  Checkbox,
  CheckboxGroup,
  NumberKeyboard,
  Popup,
  Dialog,
  Toast,
} from "vant";
import { PreSalePay } from "@get/pay";
import { mapState } from "vuex";
import { post } from "@get/http";

export default {
  components: {
    vanPasswordInput: PasswordInput,
    vanNumberKeyboard: NumberKeyboard,
    vanPopup: Popup,
    vanDialog: Dialog,
    vanCheckbox: Checkbox,
    vanCheckboxGroup: CheckboxGroup,
    [Dialog.Component.name]: Dialog.Component,
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
    }),
  },
  data() {
    return {
      allChecked: false, //全选反选按钮
      keyValue: "", //密码
      showKeyboard: true, //展开键盘
      showPassword: false, // 打开密码输入
      payment_list: {}, //可以支付的列表
      submitFrom: {
        uniapp: 0, // 是否是小程序
        pay_type: "pay_new", // 订单支付显示的数据
        payment_info: {
          password: "", // 支付密码
          rcb_pay: 0, // 是否使用充值卡进行支付
          pd_pay: 0, // 是否使用预存款进行支付
          payment_code: "alipay_h5", // 支付方式
        },
      },
    };
  },
  props: {
    addressArr: {
      //地址
      default: () => {},
    },

    list: {},
    state: {
      default: "step1",
      type: String,
    },
    remarks: {
      default: "",
      type: String,
    }, //用户备注
    balancePayState: {
      default: false,
      type: Boolean,
    }, // 是否使用零钱支付
    voucher: {
      type: Object,
      default: function() {
        return {};
      },
    }, //当前选择的优惠券

    form: {}, //  请求的form数据
    cartForm: {}, //  购物车产品
  },
  methods: {
    changePrice(price) {
      return price.toFixed(2);
    },

    async selectPay() {
      if (this.balancePayState) {
        this.showPassword = true;
      } else {
        if (this.payment_list && !this.payment_list.payment_code) {
          await this.getPayment();
        }
        this.pay();
      }
    },

    async pay() {
      Toast.loading({
        message: "调用支付中",
        forbidClick: true,
        loadingType: "spinner",
      });
      try {
        let r = this.$route.query,
          url = "/v3.MemberPresaleGoods/createPresaleGoodsDepositOrder";
        if (this.balancePayState && this.keyValue.length != 6) {
          Toast("请输入正确的6位密码");
          return false;
        }

        let subForm = {
          pd_pay: this.balancePayState ? 1 : 0, //零钱支付
          password: this.keyValue, //零钱支付密码
          payment_code: this.balancePayState
            ? "online"
            : this.payment_list.payment_code,
        };

        if (this.state == "step1") {
          subForm = {
            ...subForm,
            goods_id: this.cartForm, //购物车产品
          };
        }

        if (this.state == "buy") {
          url = "/v3.MemberPresaleGoods/createPresaleGoodsBalanceOrder";

          subForm = {
            ...subForm,
            deposit_ids: this.cartForm, //购物车产品
            address_id: this.addressArr.address_id,
            voucher_id: this.voucher.voucher_id || "",
          };
        }

        let step2 = await post(
          url,
          { data: { ...subForm } },
          { result: true, resultKey: "code" }
        );

        if (step2.code === 12001 && step2.result.payment_code === "online") {
          if (this.state == "step1") {
            this.$router.replace({
              name: "preSaleSuccess",
              query: {
                pay_sn: step2.result.pay_sn,
              },
            });
          } else {
            this.$router.replace({
              name: "BuySuccess",
              query: {
                pay_sn: step2.result.pay_sn,
              },
            });
          }
        } else if (
          step2.code === 10001 ||
          step2.code === 10006 ||
          step2.code === 10099
        ) {
          this.$emit("errorEmit", step2.message);
        } else {
          this.submitFrom.payment_info.payment_code = this.payment_list.payment_code;

          if (this.payment_list.payment_code == "wxpay_minipro") {
            wx.miniProgram.redirectTo({
              url:
                "../preSalePay/pay?action=" +
                this.submitFrom.pay_type +
                "&key=" +
                this.token +
                "&pay_sn=" +
                step2.result.pay_sn +
                "&password=" +
                this.submitFrom.payment_info.password +
                "&rcb_pay=" +
                this.submitFrom.payment_info.rcb_pay +
                "&pd_pay=" +
                this.submitFrom.payment_info.pd_pay +
                "&payment_code=" +
                this.submitFrom.payment_info.payment_code +
                "&state=" +
                this.state,
            });
            return;
          }

          PreSalePay(
            step2.result.pay_sn,
            this.submitFrom.payment_info.payment_code,
            this.token,
            this.state == "step1" ? 1 : 0

            // this.submitFrom.payment_info,
            // this.submitFrom.uniapp
          )
            .then((result) => {
              Toast("正在准备支付");
              console.log(result);
              document.write(result);
            })
            .catch(function(error) {
              if (error.message) {
                Toast(error.message);
              } else {
                console.error(error);
                // document.write(error);
              }
            });
        }
      } catch (error) {
        console.error(error);
        Toast(error);
      }
    },
    async getPayment() {
      let result = await post("/Memberpayment/payment_list");
      if (result.payment_list && result.payment_list[0]) {
        this.payment_list = result.payment_list[0];
      }
    },
    keyInput(val) {
      if (val == "忘记") {
        Dialog.confirm({
          title: "忘记密码",
          message: "需要帮忙找回密码吗？",
        })
          .then(() => {
            Toast("请联系客服");
            // on confirm
          })
          .catch(() => {
            Toast("请联系客服");
            // on cancel
          });
      } else {
        this.keyValue += val;
      }
    },
    delInput() {
      // let a =
      if (this.keyValue.length != 0) {
        this.keyValue = this.keyValue.slice(0, this.keyValue.length - 1);
      }
    },
  },
  watch: {
    showPassword() {
      if (this.showPassword == false) {
        this.keyValue = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#submit {
  z-index: 99;
  padding: 0 rem(24);
  box-sizing: border-box;
  align-items: center;
  height: rem(104);
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0 rem(-3) rem(10) rgba(204, 204, 204, 0.2);
}
.product_number {
  font-weight: 400;
  font-size: rem(28);
  line-height: rem(39);

  color: #666666;
}
.data,
.tips {
  text-align: right;
  font-size: rem(28);
}

.info {
  margin-right: rem(24);
  .des {
    text-align: right;
    color: #999999;
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(28);
  }
  .data {
    font-weight: 600;
    > i {
      color: #222;
      line-height: rem(39);
    }
    > span {
      color: #ff4848;
      font-size: rem(34);
      line-height: rem(48);
    }
  }
}
.submit_form {
  width: rem(310);
  height: rem(88);
  background: #dddddd;
  border-radius: rem(99);
  font-size: rem(28);
  text-align: center;
  color: #fff;
  line-height: rem(88);
  color: #999999;
}
.submit_form-act {
  color: #ffffff;
  background: linear-gradient(90deg, #ff6666 0%, #ff4848 100%);
}

.balance_pay_title {
  font-size: rem(36);
  color: #a7a7a9;
  padding: rem(30);
  text-align: center;
}
</style>

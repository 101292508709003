<template>
  <div>
    <van-checkbox-group
      v-model="checkResult"
      checked-color="#FF4848"
      ref="checkboxGroup"
    >
      <div class="shop_product" v-for="(item, index) in goods" :key="index">
        <h2 class="shop_title" v-if="isShopTitle && index == 0">
          <img
            src="http://image.shop.19jiang.cn/home/mofang/alioss_6107944e3a051.jpg"
          />
          十九匠官方店
        </h2>

        <div class="product_ul">
          <div class="product_item">
            <!-- :disabled="item.is_allow != 1" -->
            <van-checkbox
              @click="RadioChange"
              :name="`${item.presale_id}`"
              v-if="state == 'step2'"
              class="checkbox"
            ></van-checkbox>
            <li class="list">
              <div
                class="product_info"
                @click="
                  $router.push({
                    name: 'HomeGoodsdetail',
                    query: { goods_id: item.detail.goods_id },
                  })
                "
              >
                <img
                  class="productImage"
                  :src="item.detail.goodsimage_full_url"
                />
                <div class="content">
                  <div class="title t1">
                    {{ item.detail.goods_name }}
                  </div>
                  <div class="price t1">￥ {{ item.detail.goods_price }}</div>
                  <div class="tips">
                    <div class="t-li">
                      {{ state == "step1" ? "预付定金" : "尾款待付" }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="product_data">
                <div class="line"></div>
                <div class="step1">
                  <div class="solid"></div>
                  <span class="s_title">商品定金</span>
                  <span class="s_price">￥{{ item.presale_price }}</span>
                  <span class="s_des" v-if="state == 'step1'">待付款</span>
                  <span
                    class="s_des"
                    v-if="state == 'step2'"
                    style="color:#222;"
                    >已完成</span
                  >
                </div>
                <div class="step_payPriceText s1_payPriceText">
                  实付款 <span class="sp_price">¥{{ item.presale_price }}</span>
                </div>
                <div class="step2">
                  <div
                    class="solid"
                    :style="state == 'step1' ? 'background:#999' : ''"
                  ></div>
                  <span
                    class="s_title"
                    :style="state == 'step1' ? 'color:#999' : ''"
                    >尾款总额</span
                  >
                  <span
                    class="s_price"
                    :style="state == 'step1' ? 'color:#999' : ''"
                    >¥{{ item.balance_price }}</span
                  >

                  <span
                    class="s_des"
                    :style="
                      item.is_allow == 0
                        ? 'color:#999'
                        : item.is_allow == 1
                        ? ''
                        : 'color:#222'
                    "
                  >
                    {{
                      item.is_allow == 0
                        ? "未开始"
                        : item.is_allow == 1
                        ? "待付款"
                        : "已结束"
                    }}
                  </span>
                </div>
                <div
                  class="step_payPriceText"
                  style="text-align: left;"
                  :style="state == 'step1' ? 'color:#999' : ''"
                >
                  {{ item.balance_start_date }} 至 {{ item.balance_end_date }}
                </div>
                <div class="product_price_total" v-if="state == 'step2'">
                  <span>实付款</span>
                  <span>¥{{ parseFloat(item.balance_price) }}</span>
                </div>
              </div>

              <div class="btn-ul" v-if="state == 'step2'">
                <div class="li" @click="reductPrice">
                  退款
                </div>
              </div>
              <div class="total" v-if="state == 'step1'">
                <span>小计</span>
                <b>￥{{ parseFloat(item.presale_price) }}</b>
              </div>
            </li>
          </div>
        </div>
      </div>
    </van-checkbox-group>

    <div
      id="submit"
      v-if="state == 'step2'"
      class="submit_shadow flex-container"
    >
      <template>
        <van-checkbox
          class="check"
          v-model="allChecked"
          checked-color="#fb5839"
          @click="setAllCheck"
          >全选</van-checkbox
        >
      </template>

      <div class="info flex1">
        <p class="data">
          <i>合计:</i><span> <b>￥</b>{{ checkPrice }}</span>
        </p>
        <p class="des">活动优惠¥0</p>
      </div>

      <div
        class="submit_form"
        @click="checkResult.length != 0 ? selectPay() : ''"
        :class="checkResult.length != 0 ? 'submit_form-act' : ''"
      >
        去结算
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, Toast, Dialog } from "vant";
export default {
  components: {
    vanCheckbox: Checkbox,
    vanCheckboxGroup: CheckboxGroup,
  },
  props: {
    isCheck: {
      default: false,
    },
    state: {
      default: "step1",
    },
    isShopTitle: {
      default: false,
    },
    goods: {
      default: () => [],
    },
  },
  data() {
    return {
      checkResult: [], //选中的产品
      allChecked: false, //是否全选
      cart_id: "", //付款ID
      checkPrice: 0, //选中的价格
    };
  },
  mounted() {
    // this.setAllCheck();
  },
  methods: {
    selectPay() {
      let re = false;
      for (let index = 0; index < this.checkResult.length; index++) {
        re = this.goods.find((item) => {
          if (
            this.checkResult[index] == item.presale_id &&
            item.is_allow != 1
          ) {
            return true;
          }
        });
        if (re) break;
      }

      if (re) {
        Toast(`支付尾款是${re.balance_start_date}-${re.balance_end_date}`);
        return;
      }

      this.$router.push({
        name: "preSaleBuy",
        query: { cart_id: this.cart_id },
      });
    },
    reductPrice() {
      //退款
      Toast("付尾款后才可以退款哦~");
    },
    RadioChange(data) {
      //单选
      let disables = 0;
      // for (let index = 0; index < this.goods?.length; index++) {
      //   if (this.goods[index].is_allow != 1) {
      //     disables += 1;
      //     // return item;
      //   }
      // }
      //检测是否全选

      if (this.checkResult.length != this.goods.length - disables) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
      this.getFindCartChcekData();
    },
    setAllCheck() {
      //全选反选

      if (this.allChecked) {
        this.$refs.checkboxGroup.toggleAll({
          checked: true,
          skipDisabled: true,
        });
      } else {
        this.$refs.checkboxGroup.toggleAll(false);
      }
      this.getFindCartChcekData();
    },

    getFindCartChcekData() {
      //合计
      let price = 0,
        num = "";
      this.checkResult.map((item, i) => {
        for (let index = 0; index < this.goods.length; index++) {
          let list = this.goods[index];
          if (list.presale_id == item) {
            price += parseFloat(list.balance_price);
            num += `${item}${this.checkResult.length - 1 == i ? "" : ","}`;
            return list;
          }
        }
      });
      this.cart_id = num;
      this.checkPrice = price.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.shop_product {
  background: #fff;
  border-radius: rem(16);
  width: rem(710);
  margin: 0 auto;
  box-sizing: border-box;
  padding: rem(30) rem(24);
  margin-bottom: rem(24);
  .shop_title {
    height: rem(50);
    line-height: rem(50);
    margin-bottom: rem(24);
    img {
      width: rem(50);
    }
    font-size: rem(32);
    color: #484848;
  }
  .product_ul {
  }
  .product_item {
    display: flex;
    align-items: flex-start;
    .list {
      .product_info {
        display: flex;
        .productImage {
          width: rem(160);
          height: rem(160);
          border-radius: rem(8);
          margin-right: rem(16);
        }
        .content {
          .t1 {
            font-weight: 600;
            font-size: rem(30);
            line-height: rem(42);
            height: rem(42);
            overflow: hidden;
          }
          .title {
            color: #222222;
            margin-bottom: rem(16);
          }
          .price {
            color: #ff4848;
            margin-bottom: rem(30);
          }
          .tips {
            .t-li {
              display: inline-block;
              padding: 0 rem(9);

              text-align: center;
              font-weight: 400;
              font-size: rem(20);
              height: rem(30);
              line-height: rem(32);
              color: #ff4848;
              background: rgba(255, 72, 72, 0.1);
              border-radius: rem(4);
            }
          }
        }
      }
      .product_price_total {
        margin-top: rem(8);
        text-align: right;
        > span {
          font-weight: 400;
          font-size: rem(30);
          line-height: rem(42);

          &:first-of-type {
            margin-right: rem(8);
          }
        }
      }
      .product_data {
        padding: rem(24);
        box-sizing: border-box;
        margin-top: rem(32);
        background: #f8f8f8;
        border-radius: rem(8);
        position: relative;

        .line {
          position: absolute;
          width: 1px;
          top: rem(52);
          height: rem(96);
          left: rem(29);
          border-left: 1px dashed #ff4848;
        }
        .step_payPriceText {
          font-weight: 400;
          font-size: rem(24);
          line-height: rem(34);
          color: #222222;
          text-align: right;
          .sp_price {
            margin-left: rem(8);
          }
        }
        .s1_payPriceText {
          margin-bottom: rem(32);
        }
        .step1,
        .step2 {
          display: flex;
          align-items: center;
          margin-bottom: rem(8);
        }

        .solid {
          width: rem(12);
          height: rem(12);
          border-radius: 50%;
          background: #ed2222;
          margin-right: rem(16);
        }
        .s_price {
          flex: 1;
        }
        .s_title,
        .s_price {
          font-weight: 400;
          font-size: rem(28);
          line-height: rem(39);
          margin-right: rem(8);

          color: #222222;
        }
        .s_des {
          font-weight: 600;
          font-size: rem(28);
          line-height: rem(39);
          color: #ff4848;
        }
      }
      .btn-ul {
        font-weight: 400;
        font-size: rem(28);
        display: flex;
        margin-top: rem(32);
        justify-content: flex-end;
        .li {
          height: rem(63);
          padding: 0 rem(24);
          border: 1px solid #eee;
          line-height: rem(63);
          color: #999999;
          border-radius: rem(63/2);
        }
      }
      .total {
        margin-top: rem(62);
        font-weight: 400;
        font-size: rem(28);
        line-height: rem(39);
        text-align: right;
        > span {
          color: #222222;
        }
        > b {
          color: #ff4848;
        }
      }
    }
  }
}

.checkbox {
  margin-right: rem(24);
  margin-top: rem(58);
}
::v-deep {
  .van-icon {
    width: rem(44);
    line-height: rem(44);
    height: rem(44);
  }

  .van-checkbox {
    overflow: inherit;
  }
}

#submit {
  z-index: 99;
  padding: 0 rem(24);
  box-sizing: border-box;
  align-items: center;
  height: rem(104);
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0 rem(-3) rem(10) rgba(204, 204, 204, 0.2);
}
.product_number {
  font-weight: 400;
  font-size: rem(28);
  line-height: rem(39);

  color: #666666;
}
.data,
.tips {
  text-align: right;
  font-size: rem(28);
}

.info {
  margin-right: rem(24);
  .des {
    text-align: right;
    color: #999999;
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(28);
  }
  .data {
    font-weight: 600;
    > i {
      color: #222;
      line-height: rem(39);
    }
    > span {
      color: #ff4848;
      font-size: rem(34);
      line-height: rem(48);
    }
  }
}
.submit_form {
  width: rem(310);
  height: rem(88);
  background: #dddddd;
  border-radius: rem(99);
  font-size: rem(28);
  text-align: center;
  color: #fff;
  line-height: rem(88);
  color: #999999;
}
.submit_form-act {
  color: #ffffff;
  background: linear-gradient(90deg, #ff6666 0%, #ff4848 100%);
}

.balance_pay_title {
  font-size: rem(36);
  color: #a7a7a9;
  padding: rem(30);
  text-align: center;
}
</style>

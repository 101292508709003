<template>
  <div>
    <ul class="vouchers">
      <li class="flex-container v-li">
        <img class="icon" src="@image/order/order_price.png" />
        <span class="title">零钱支付</span>
        <i class="balance flex1">(余额:{{ balance }})</i>
        <van-switch
          @change="changeChecked"
          active-color="#f65a21"
          v-model="checked"
          size="20px"
          class="switch"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { Switch, Toast } from "vant";
export default {
  props: {
    balance: {},
    balancePayState: {},
    totalPrice: {},
  },
  components: {
    vanSwitch: Switch,
  },
  mounted() {},

  created() {},
  methods: {
    changeChecked(val) {
      if (
        +parseFloat(this.balance).toFixed(2) <
        +parseFloat(this.totalPrice).toFixed(2)
      ) {
        Toast("零钱余额不足不能使用~");
        this.checked = false;
        this.$emit("update:balancePayState", false);
      } else {
        this.$emit("update:balancePayState", val);
      }
    },
  },
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.vouchers {
  background: #fff;
  width: rem(710);
  margin: 0 auto;
  border-radius: rem(16);
  padding: rem(20);
  box-sizing: border-box;
  .v-li {
    align-items: center;
    justify-content: center;
    margin-bottom: rem(30);
    &:last-of-type {
      margin-bottom: 0;
    }
    .icon {
      width: rem(30);
    }
    .switch {
      // margin-right: rem(20);
    }
    .balance {
      color: #a7a7a9;
      // font-size: rem(20);
      margin-left: rem(20);
      margin-top: rem(4);
    }
    .title {
      margin-left: rem(10);
      font-size: rem(28);
      color: #232323;
    }
    .price {
      color: #f65a21;
      font-size: rem(28);
      margin-right: rem(20);
    }
    .more {
      width: rem(23);
    }
  }
}
</style>
